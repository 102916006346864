<template>
  <b-form-group
    label="Randevu İptali"
    label-for="id_com_service_status"
  >
    <v-select
      id="id_com_service_status"
      v-model="service.id_com_service_status"
      label="title"
      :reduce="item => item.id"
      :clearable="false"
      :options="statuses"
      placeholder="İptal işlemi için seçiniz.."
    />

  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { localize } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'CancelStatus',
  components: {
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      required,
      cancelStatuses: [
        {
          id: '5',
          title: 'Evet',
        },
      ],
      status: 0,
    }
  },
  computed: {
    service() {
      return this.$store.getters['serviceTracing/getService']
    },
    statuses() {
      return this.cancelStatuses
    },
  },
  created() {
    localize('tr')
    this.getMeets()
  },

}
</script>
